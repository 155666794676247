/* eslint-disable react/no-unescaped-entities */

import React from "react";
import { Container } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Layout, Seo } from "../components";

const AttributionPage = (props) => (
  <Layout {...props}>
    <Seo location="attribution" title="seoAttributionPageTitle" />
    <Container fluid="lg" className="py-4 py-md-5">
      <h1 className="h2">
        <FormattedMessage id="attributionTitle" defaultMessage="Icons used" />
      </h1>
      <ul>
        <li>
          <a
            href="https://thenounproject.com/ralfschmitzer/"
            target="_blank"
            rel="noreferrer"
          >
            wedding invitations by Ralf Schmitzer from the Noun Project
          </a>
        </li>
        <li>
          <a
            href="https://thenounproject.com/ralfschmitzer/"
            target="_blank"
            rel="noreferrer"
          >
            team by Ralf Schmitzer from the Noun Project
          </a>
        </li>
        <li>
          <a
            href="https://thenounproject.com/ralfschmitzer/"
            target="_blank"
            rel="noreferrer"
          >
            perk by Ralf Schmitzer from the Noun Project
          </a>
        </li>
        <li>
          <a
            href="https://thenounproject.com/morbidillusion/"
            target="_blank"
            rel="noreferrer"
          >
            Facebook by Saloni Sinha from the Noun Project
          </a>
        </li>
        <li>
          <a
            href="https://thenounproject.com/morbidillusion/"
            target="_blank"
            rel="noreferrer"
          >
            instagram by Saloni Sinha from the Noun Project
          </a>
        </li>
        <li>
          <a
            href="https://thenounproject.com/morbidillusion/"
            target="_blank"
            rel="noreferrer"
          >
            Twitter by Saloni Sinha from the Noun Project
          </a>
        </li>
      </ul>
    </Container>
  </Layout>
);

export default AttributionPage;
